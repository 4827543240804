<!--
 * @FileDescription: 详情页使用table组件
 * @Author: 朱建波
 * @Date: 2024-05-31
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZTable
 *
 * @Props
 * @property {Array} data                  显示数据
 * @property {String} config               list数据为对象时，key值
 * @property {Number} border               = [true|false]   是否显示border，默认为 false
 * @property {Number} stripe               = [true|false]   是否显示斑马线，默认为 false
 * @property {Number} emptyText            数据为空时显示文本
 * @property {Number} headerStyle          自定义头部样式
 *
 * @Slots
 *
 * @Methods
 * change(index)                            改变选中index
-->
<script>
export default {
  props: {
    data: {type: Array, default: () => [] },
    config: {type: Array, default: () => [] },
    border: {type: Boolean, default: false },
    stripe: {type: Boolean, default: false },
    emptyText: {type: String, default: '暂无数据' },
    headerColor: {type: String, default: '#333' },
    headerBgColor: {type: String, default: '#F0F7FF' },
    headerStyle: {type: Object, default: () => ({}) }
  }
}
</script>

<template>
  <el-table :data="data" :border="border" :header-cell-style="{color: headerColor, background: headerBgColor, ...headerStyle}" :stripe="stripe">
    <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in config" :key="item.prop + index"></el-table-column>
    <template #empty>
      <t-result type='empty' :text="emptyText"></t-result>
    </template>
  </el-table>
</template>

<style lang="scss" scoped></style>
